import React from "react"
import theme from "theme"
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Ev | Regnum Golf Club'a Hoş Geldiniz</title>
        <meta
          name={"description"}
          content={"Her Salıncağın Bir Hikaye Anlattığı Yer"}
        />
        <meta
          property={"og:title"}
          content={"Ev | Regnum Golf Club'a Hoş Geldiniz"}
        />
        <meta
          property={"og:description"}
          content={"Her Salıncağın Bir Hikaye Anlattığı Yer"}
        />
        <meta
          property={"og:image"}
          content={"https://yixelbrix.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://yixelbrix.com/img/5949968.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://yixelbrix.com/img/5949968.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://yixelbrix.com/img/5949968.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://yixelbrix.com/img/5949968.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://yixelbrix.com/img/5949968.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://yixelbrix.com/img/5949968.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 0px"
          justify-content="center"
          lg-order="1"
        >
          <Text
            margin="0px 0px 24px 0px"
            color="--dark"
            font='normal 700 62px/1.2 "Source Sans Pro", sans-serif'
            lg-text-align="center"
          >
            Regnum Golf Club
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            color="--greyD3"
            font="--lead"
            lg-text-align="center"
          >
            Golf tutkusunun doğanın dinginliğiyle buluştuğu Regnum Golf Club'a
            hoş geldiniz. Kulübümüz, misafirperver ve kapsayıcı bir ortamda
            güzel oyunun tadını çıkarmak isteyen hem meraklılar hem de
            profesyoneller için tasarlanmıştır. Burada her ziyaret, golfçüler
            arasında rahatlama, meydan okuma ve dostluğun bir karışımını vaat
            ediyor.
          </Text>
          <Link
            href="/contacts"
            padding="12px 24px 12px 24px"
            color="--light"
            text-decoration-line="initial"
            font="--lead"
            border-radius="8px"
            margin="0px 16px 0px 0px"
            transition="background-color 0.2s ease-in-out 0s"
            sm-margin="0px 0px 16px 0px"
            sm-text-align="center"
            background="--color-primary"
            hover-transition="background-color 0.2s ease-in-out 0s"
            hover-background="--color-primary"
          >
            Kişiler
          </Link>
        </Box>
        <Box
          display="flex"
          width="50%"
          justify-content="flex-end"
          lg-width="100%"
          align-items="flex-start"
          lg-margin="0px 0px 32px 0px"
          margin="0px 0px 0px 0px"
          padding="0px 0px 0px 32px"
          lg-padding="0px 0px 0px 0px"
          lg-justify-content="center"
        >
          <Image
            src="https://yixelbrix.com/img/1.jpg"
            object-fit="cover"
            width="100%"
            height="100%"
            border-radius="24px"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            min-height="600px"
            max-width="480px"
            sm-min-height="100vw"
          />
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://yixelbrix.com/img/2.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Regnum Hakkında
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            İnişli çıkışlı tepelerin ve pitoresk manzaraların ortasında yer alan
            Regnum, benzersiz bir golf deneyimi sunmaktadır. Titizlikle korunan
            18 delikli sahamız her beceri seviyesinden oyuncuyu zorlar ve memnun
            eder. Fairway'lerin ötesinde kulübümüz, gevşeyebileceğiniz,
            sosyalleşebileceğiniz ve golfçüler düşünülerek tasarlanmış en iyi
            olanaklarla kendinizi şımartabileceğiniz bir sığınaktır.
          </Text>
        </Box>
      </Section>
      <Section padding="80px 0 130px 0" sm-padding="60px 0 60px 0">
        <Override
          slot="SectionContent"
          flex-direction="row"
          md-flex-wrap="wrap"
          flex-wrap="wrap"
        />
        <Box
          width="100%"
          margin="0px 0px 64px 0px"
          md-margin="0px 0px 30px 0px"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--dark"
            font="--headline2"
            width="75%"
            lg-width="100%"
            lg-text-align="center"
          >
            Neden Regnum'i Seçmelisiniz?
          </Text>
        </Box>
        <Box display="flex" width="100%" md-flex-wrap="wrap">
          <Box
            width="48.5%"
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            md-width="100%"
            padding="0px 0px 0px 0px"
            md-padding="0px 0px 0px 0px"
            md-margin="0px 0px 40px 0px"
            margin="0px 3% 0px 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL2"
              font="--base"
              md-text-align="center"
            >
              Bozulmamış Sahalar: Meydan okumak ve ilham vermek için
              tasarlanmış, kusursuz bir şekilde bakımı yapılmış
              yeşilliklerimizde ve çim yollarımızda golf mükemmelliğini yaşayın.
              <br />
              <br />
              Özel Üye Avantajları: Golf yolculuğunuzu geliştiren öncelikli
              erişimin, özel fiyatların ve üyelere özel etkinliklerin keyfini
              çıkarın.
            </Text>
          </Box>
          <Box
            width="48.5%"
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            md-width="100%"
            padding="0 0px 0 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL2"
              font="--base"
              md-text-align="center"
            >
              Aile Dostu Atmosfer: Golfün bir aile meselesi olduğuna inanıyoruz.
              Sahalarımız ve kulüp tesislerimiz her yaştan oyuncunun keyif
              alabileceği şekilde tasarlanmıştır.
              <br />
              <br />
              Olağanüstü Yemekler: Her yemeğin kurslarımız kadar özenle
              hazırlandığı gurme yemek seçeneklerimizin lezzetlerinin tadını
              çıkarın.
            </Text>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
